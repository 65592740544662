import { BehaviorSubject, debounceTime } from "rxjs";
import tournamentService from "../../Services/TournamentDataService";
import MessageService from "../../Services/MessageService";
import { NavbarData, NavbarProperties, SyncToolConfigData } from "../../components/navbar/Navbar.ViewModel";
import { PrizeoutStandingViewModel } from "../PrizeoutStanding/PrizeoutStanding.ViewModel";
import { PrizeoutEventData } from "../PrizeoutEventSelector/PrizeoutEventSelector.ViewModel";
import { NavbarSyncToolIcon } from "../../components/navbar/NavbarSyncTool/NavbarSyncTool";
import { CommonFormatsService as formats } from "../../Services/CommonFormatsService";
import { Subscription } from "rxjs";
import { PrizeoutRecordsChangesTransfer } from "../../Models/Inbound/PrizeoutRecordsChangesTransfer";
import { prizeoutPrizeType } from "../../Models/Common/PrizeoutPrizeType";
import { DataUpdateNotificationMessage, UpdateNotificationType } from "../../Models/Messages/DataUpdateNotificationMessage";
import { apiPrizeoutStanding } from "../../Models/Inbound/ApiPrizeoutStandingsEvent";

export class PrizeoutDashboardViewModel {
    navbar: NavbarProperties
    isLoadingEvents = new BehaviorSubject<boolean>(false);
    selectedEvents = new BehaviorSubject<PrizeoutEventData[]>([]);
    selectedEventGroups = new BehaviorSubject<string[]>([]);
    combinedStandings = new BehaviorSubject<PrizeoutStandingViewModel[]>([]);
    currentUsername = new BehaviorSubject<string>('');
    isReadOnly: boolean;
    pushPrizeoutEditsTrigger = new BehaviorSubject<void>(void 0);

    standingsSubscription: Subscription | null;

    constructor(
        navbarProperties: NavbarProperties,
        isReadOnly: boolean = false) {
        this.navbar = navbarProperties;
        this.isReadOnly = isReadOnly;

        const urlParams = new URLSearchParams(window.location.search);
        const userParam = urlParams.get('user');
        if (userParam) {
            this.updateUsername(userParam);
        }
        this.navbar.data.next(
            new NavbarData(
                "Prize Out Tools")
        );
        this.navbar.syncToolConfig.isSyncActive.next(true);
        this.navbar.syncToolConfig.isSyncFeatureEnabled.next(true);
        this.navbar.syncToolConfig.data.next(new SyncToolConfigData(
            false,
            null,
            NavbarSyncToolIcon.Disabled,
            "Data sync",
            "Enable for background updates",
            ""
        ));

        MessageService.subscribe((message: DataUpdateNotificationMessage) => {
            if (!this.navbar.syncToolConfig.isSyncActive.getValue()) {
                return;
            }
            switch (message.type) {
                case UpdateNotificationType.PrizeoutStandings, UpdateNotificationType.EventData:
                    const eventId = message.recordIdentifier;
                    if (this.selectedEvents.getValue().some(event => event.eventId === eventId)) {
                        console.log("Event data update notification received");
                        this.refreshDataForEvent(eventId);
                    }
                    break;
                case UpdateNotificationType.PrizeoutRecord:
                    const existingStanding = this.combinedStandings.getValue().find(standing => standing.prizeoutUid === message.recordIdentifier);
                    if (existingStanding) {
                        console.log("Prizeout records changes notification received");
                        tournamentService.GetPrizeoutRecord(message.recordIdentifier).subscribe(result => {
                            if (result.isSuccess() && result.result) {
                                this.processPrizeoutRecordUpdate(result.result);
                            }
                        });
                    }
                    break;
                case UpdateNotificationType.EventGroup:
                    const groupId = message.recordIdentifier;
                    if (this.selectedEventGroups.getValue().some(selectedGroupId => selectedGroupId === groupId)) {
                        console.log("EventGroupId data update notification received");
                        this.refreshDataForGroup(groupId);
                    }
                    break;
            }
        });

        // Actively tries to save local changes when one is detected
        this.combinedStandings.pipe(
            debounceTime(3000)
        ).subscribe(standings => {
            var changes = standings.map(standing => {
                var converted = standing.toPrizeoutRecordsChangeIfEdited(this.currentUsername.value ?? "No user")
                if (converted !== null) {
                    console.log("Preparing to push prizeout fulfilment update: " + converted.prizeoutUid + " (" + standing.firstName + " " + standing.lastName + ") with " + converted.updatedPrizeFulfilments.length + " changes");
                }
                return converted;
            }).filter(change => change !== null);

            if (changes.length > 0) {
                console.log("Pushing prizeout changes: " + changes.length);
                var transfer = new PrizeoutRecordsChangesTransfer();
                transfer.updatedRecords = changes;
                tournamentService.PutPrizeoutRecordsChanges(transfer).subscribe(result => {
                    console.log("Pushed prizeout changes.");
                    if (result.isSuccess() && result.result) {
                        // result.result.map(record => {
                        //     this.processPrizeoutRecordUpdate(record);
                        // });
                    }});
            }
        });

        // Passively loads standings data from server, with optional manual trigger
        this.selectedEvents.subscribe(selectedEvents => {
            this.isLoadingEvents.next(true);
            const selectedEventIds = selectedEvents.map(event => event.eventId);
            const existingStandings = this.combinedStandings.getValue();

            // Remove standings that do not match any selected eventId
            const filteredStandings = existingStandings.filter(standing => selectedEventIds.includes(standing.eventId));
            this.combinedStandings.next(filteredStandings);

            // Call refreshDataForEvent for any eventId in selectedEvents but not in combinedStandings
            let pendingEvents = selectedEventIds.length;
            selectedEventIds.forEach(eventId => {
                if (!filteredStandings.some(standing => standing.eventId === eventId)) {
                    this.refreshDataForEvent(eventId, () => {
                        pendingEvents--;
                        if (pendingEvents === 0) {
                            this.isLoadingEvents.next(false);
                        }
                    });
                } else {
                    pendingEvents--;
                }
            });
            if (pendingEvents === 0) {
                this.isLoadingEvents.next(false);
            }
        });
    }

    refreshDataForEvent = (eventId: string, onComplete?: () => void) => {
        this.standingsSubscription = tournamentService.GetPrizeoutStandingsRecords(eventId).subscribe(apiStandings => {
            try {
                if (apiStandings && apiStandings.isSuccessNoContent()) {
                    console.log("No content for event ");
                    return;
                } else if (!apiStandings || !apiStandings.isSuccess || !apiStandings.result) {
                    console.error(apiStandings?.result)
                    console.error("Error detected in standings results for event " + apiStandings?.result?.eventId);
                    return;
                } else {
                    const existingStandings = this.combinedStandings.getValue();
                    const updatedStandingsMap = new Map<string, PrizeoutStandingViewModel>();
                    const selectedEvents = this.selectedEvents.getValue();

                    // Add existing standings to the map first, excluding those with matching eventId
                    existingStandings.forEach(standing => {
                        if (standing.eventId !== apiStandings.result!.eventId) {
                            updatedStandingsMap.set(standing.prizeoutUid, standing);
                        }
                    });

                    // Update the map with new or modified standings
                    apiStandings.result.divisions.forEach(division => {
                        division.standings.forEach(standing => {
                            const existingStanding = updatedStandingsMap.get(standing.prizeoutUid);
                            if (existingStanding) {
                                existingStanding.updateServerData(standing, apiStandings.result!, division, selectedEvents.find(event => event.eventId === apiStandings.result!.eventId)?.details ?? null);
                            } else {
                                updatedStandingsMap.set(standing.prizeoutUid, new PrizeoutStandingViewModel(standing, apiStandings.result!, division, selectedEvents.find(event => event.eventId === apiStandings.result!.eventId)?.details ?? null));
                            }
                        });
                    });

                    const updatedStandings = Array.from(updatedStandingsMap.values());
                    this.combinedStandings.next(updatedStandings);
                }

                // Update navbar state
                this.navbar.syncToolConfig.data.next(new SyncToolConfigData(
                false,
                apiStandings ?? null,
                NavbarSyncToolIcon.Sync,
                "Prize Out",
                "Standings data sync",
                "Updated: " + formats.formatOptionalDateCompact(new Date().toISOString()),
                ));
            } catch (error) {
                console.error("Error detected in standings results for event " + apiStandings?.result?.eventId);
            } finally {
                if (onComplete) {
                    onComplete();
                }
            }
        });
    }

    refreshDataForGroup = (groupId: string) => {
        tournamentService.GetAllEvents().subscribe(result => {
            if (result.isSuccess() && result.result) {
                const newEvents = result.result.events
                    .filter(event => event.eventGroupId === groupId && !this.selectedEvents.getValue().some(selectedEvent => selectedEvent.eventId === event.eventId))
                    .map(event => new PrizeoutEventData(event));
                if (newEvents.length > 0) {
                    this.updateSelected([...this.selectedEvents.getValue(), ...newEvents], this.selectedEventGroups.getValue());
                }
            }
        });
    }

    processPrizeoutRecordUpdate = (update: apiPrizeoutStanding) => {
        const existingStandings = this.combinedStandings.getValue();
        const updatedStandings = existingStandings.map(standing => {
            if (standing.prizeoutUid === update.prizeoutUid) {
                standing.updateServerPrizeDataOnly(update);
            }
            return standing;
        });
        this.combinedStandings.next(updatedStandings);
    }

    pushPrizeoutEdits = () => {
        this.pushPrizeoutEditsTrigger.next();
    }

    public updateSelected = (selectedEvents: PrizeoutEventData[], selectedGroups: string[]) => {
        selectedGroups.forEach(groupId => this.refreshDataForGroup(groupId));
        this.updateQueryParams(selectedEvents, selectedGroups);
        console.log(`Finished selecting ${selectedEvents.length} events and ${selectedGroups.length} groups`);
        this.selectedEvents.next(selectedEvents);
        this.selectedEventGroups.next(selectedGroups);
    }

    public updateUsername = (username: string) => {
        console.log("Updating username to " + username);
        this.currentUsername.next(username);

        // Update the user query param in the URL
        const url = new URL(window.location.href);
        url.searchParams.set('user', username);
        window.history.replaceState({}, '', url.toString());
    }

    public editPrizeoutComment = (prizeoutUid: string, comment: string | null) => {
        console.log(`Editing comment for ${prizeoutUid} to ${comment}`);
        const standings = this.combinedStandings.getValue();
        const standingToEdit = standings.find(standing => standing.prizeoutUid === prizeoutUid);
        if (standingToEdit) {
            standingToEdit.editComment(comment);
            this.combinedStandings.next(standings);
        } else {
            console.error(`No standing found with prizeoutUid: ${prizeoutUid}`);
        }
    }   

    public editPrizeAwarded = (prizeoutUid: string, prizeGuid: string, newQuantityReceived: number | null) => {
        console.log(`Editing prizeout for ${prizeoutUid} - ${prizeGuid} to ${newQuantityReceived}`);
        const standings = this.combinedStandings.getValue();
        const standingToEdit = standings.find(standing => standing.prizeoutUid === prizeoutUid);
        if (standingToEdit) {
            standingToEdit.editPrizeFulfilment(prizeGuid, newQuantityReceived);
            this.combinedStandings.next(standings);
        } else {
            console.error(`No standing found with prizeoutUid: ${prizeoutUid}`);
        }
    }

    public getStatistics = () => {
        const prizeTypeCounts: { [key: string]: string } = {};
        for (const prizeType of Object.values(prizeoutPrizeType).filter(value => typeof value === 'number') as prizeoutPrizeType[]) {
            const readableType = prizeoutPrizeType.toReadableString(prizeType);
            const quantityEarned = this.combinedStandings.value.reduce((count, standing) => {
            return count + standing.prizefulfilments.filter(prize => prize.type === prizeType).reduce((sum, prize) => sum + (prize.quantityEarned ?? 0), 0);
            }, 0);
            const remoteQuantityReceived = this.combinedStandings.value.reduce((count, standing) => {
            return count + standing.prizefulfilments.filter(prize => prize.type === prizeType).reduce((sum, prize) => sum + (prize.remoteQuantityReceived ?? 0), 0);
            }, 0);
            prizeTypeCounts[readableType] = `${quantityEarned - remoteQuantityReceived} + ${remoteQuantityReceived} = ${quantityEarned}`;
        }
        return `Counts ( Unprized + Prized = Total ):\n` +
            `Players ${this.combinedStandings.value.filter(standing => !standing.isFullyPrizedOut()).length} + ${this.combinedStandings.value.filter(standing => standing.prizesDueAndFullyPrizedOut()).length} = ${this.combinedStandings.value.filter(standing => standing.prizesDue()).length}\n` +
            Object.entries(prizeTypeCounts).map(([type, count]) => `${type}: ${count}`).join('\n');
    }

    updateQueryParams = (selectedEvents: PrizeoutEventData[], selectedGroups: string[]) => {
        const params = new URLSearchParams(window.location.search);
        const eventIds = selectedEvents.map(event => event.eventId).join(',');
        const groupIds = selectedGroups.join(',');
        params.set('selectedEvents', eventIds);
        params.set('selectedGroups', groupIds);
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    }
}