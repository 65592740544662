import { motion, useAnimationControls } from "framer-motion";
import GlassCard from "../../components/glassTheme/GlassCard";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import '../../components/glassTheme/NumberInput.scss';
import './Uploader.scss';
import React, { Fragment, useEffect, useState } from 'react';
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import { FaRegPaperPlane, FaTrophy, FaTape, FaMapPin, FaStar, FaCopy } from 'react-icons/fa';
import { PiListNumbersFill } from "react-icons/pi";
import { MdOutlineCloudUpload, MdOutlineRefresh } from "react-icons/md";
import { LuListChecks } from "react-icons/lu";
import { IoPeopleCircleOutline } from "react-icons/io5";
import { TbBuildingBroadcastTower } from "react-icons/tb";
import { apiSampleTabOptions, apiSampleViewModel, selectedStandingsViewModel, selectedTournamentFileViewModel } from "../ScorekeeperDashboard/ScorekeeperDashboard.ViewModel";
import { BehaviorSubject, Subscription } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import AutoUploadControls from "../AutoUploadControls/AutoUploadControls";
import UploadTracker, { UploadTrackerIcon } from "../../components/UploadTracker/UploadTracker";
import { FaFlagCheckered } from 'react-icons/fa';
import { eventViewModel } from "../../EventsDashboard/EventsDashboard/EventsDashboard.ViewModel";
import { eventState } from "../../Models/Common/EventState";
import { competitorDivision } from "../../Models/Common/CompetitorDivision";
import IconButton, { IconButtonType } from "../../components/IconButton/IconButton";
import FusionControls from "../FusionControls/FusionControls";
import { fusionDataChannel } from "../../Models/Common/FusionDataChannel";
import { LogbookBundleViewModel } from "../../components/UploadTracker/LogbookViewModel";
import DivisionSelector from "../../components/selectors/DivisionSelector";
import acs from "../../Services/ApiConnectService";

interface UploaderSubviewProps {
  eventId: string;
  apiSampleSubject: BehaviorSubject<apiSampleViewModel>;
  eventDataSubject: BehaviorSubject<eventViewModel | null>;
  tournamentFileSubject: BehaviorSubject<selectedTournamentFileViewModel>;
  standingsSubject: BehaviorSubject<selectedStandingsViewModel>;
  switchResultsTab : (tab: apiSampleTabOptions, division: competitorDivision) => void;
  updateSelectedTournamentFile : (file: string) => void;
  updateSelectedStandingsValue : (value: string) => void;
  standingsReportDivisionChange : (value: competitorDivision) => void;
  manuallySetSelectedStandingsValue : (value: number) => void;
  setStandingsIsFinal : (value: boolean) => void;
  setStandingsIsDeleteInstruction : (value: boolean) => void;
  setStandingsCutSize: (value: number) => void;
  uploadTournamentFile : (shouldStoreTdf: boolean, isFinalUpload: boolean) => void;
  uploadFusionImport : (shouldResetCore: boolean, shouldResetSupportCache: boolean, channel: number, isDataImportOnly: boolean, skipValidation: boolean) => void;
  exportTdf : (channel: fusionDataChannel | null) => void;
  uploadStandings : () => void;
  editFusionDataChannel: (channel: fusionDataChannel | null) => void;
  localFusionDataChannelSubject: BehaviorSubject<fusionDataChannel | null>;
  fusionLogbookBundlesSubject: BehaviorSubject<LogbookBundleViewModel[]>;
}

const standingsPlaceholder = "Paste partial or complete standings from TOM report here. Do not include the header row and check that the results are formatted correctly."

const Uploader: React.FC<UploaderSubviewProps> = ({
  eventId,
  apiSampleSubject,
  eventDataSubject,
  tournamentFileSubject,
  standingsSubject,
  switchResultsTab,
  updateSelectedTournamentFile,
  updateSelectedStandingsValue,
  standingsReportDivisionChange,
  manuallySetSelectedStandingsValue,
  setStandingsIsFinal,
  setStandingsIsDeleteInstruction,
  setStandingsCutSize,
  uploadTournamentFile,
  uploadFusionImport,
  exportTdf,
  uploadStandings,
  editFusionDataChannel,
  localFusionDataChannelSubject,
  fusionLogbookBundlesSubject }) => {

  const [apiSample, setApiSample] = useState<apiSampleViewModel>();
  const [eventData, setEventData] = useState<eventViewModel | null>(null);
  const [tournamentFile, setTournamentFile] = useState<selectedTournamentFileViewModel | null>(null);
  const [isTournamentFileSelectedManually, setIsTournamentFileSelectedManually] = useState<boolean>(false);
  const [standings, setStandings] = useState<selectedStandingsViewModel | null>(null);
  const [shouldStoreTdf, setShouldStoreTdf] = useState<boolean>(true);
  const [isFinalUpload, setIsFinalUpload] = useState<boolean>(false);
  const [isCompatibilityMode, setIsCompatibilityMode] = useState<boolean>(false);
  const [fusionLogbooks, setFusionLogbooks] = useState<LogbookBundleViewModel[]>([]);
  const [showDebugLogs, setShowDebugLogs] = useState<boolean>(false);
  const [apiSampleSelectedDivision, setApiSampleSelectedDivision] = useState<competitorDivision>(competitorDivision.Master);
  const animationControl = useAnimationControls();

  useEffect(() => {
    var subs: Subscription[] = [];
    subs.push(apiSampleSubject.subscribe((value) =>
      {
        if (value.response !== "") {
          animationControl.start("onscreen");
        }
        setApiSample({...value});
      }));
    subs.push(eventDataSubject.subscribe(value =>
      {
        setEventData(value ? {...value} : null)
      }));
    subs.push(tournamentFileSubject.subscribe(value =>
      {
        if (value.file === "") {
          resetTournamentFile();
        }
        setTournamentFile({...value})
      }));
    subs.push(standingsSubject.subscribe(value => 
      {
        if (value.value === "") {
          resetStandings();
        }
        setStandings({...value})
      }));
      subs.push(fusionLogbookBundlesSubject.subscribe(value => setFusionLogbooks([...value])));
    return () => subs.forEach(s => s.unsubscribe());
  }, [eventId, apiSampleSubject, tournamentFileSubject, standingsSubject, animationControl, eventDataSubject]);

  const animationVariants = {
    offscreen: { delay: 0, opacity: 0, scale: 0.6, transition: { duration: 0.5 } },
    onscreen: { delay: 0, opacity: 1, scale: 1, transition: { duration: 0.2 } }
  };

  const copyText = async () => {   
    var copyText = document.getElementById("resultText") as HTMLInputElement;
    if (!copyText) {
      return;
    }
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand("Copy");
    copyText.blur();
  }

  const manuallySelectedTournamentFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (!input || !input.files?.length) {
      return;
    }
    var file = input.files[0];
    var reader = new FileReader()
    reader.onload = async (e: ProgressEvent<FileReader>) => { 
      if (!e.target?.result) {
        return;
      }
      setIsTournamentFileSelectedManually(true);
      updateSelectedTournamentFile(e.target.result as string);
    };
    reader.readAsText(file)
  }

  const automaticallySelectedTournamentFile = async (file: string) => {
    setIsTournamentFileSelectedManually(false);
    updateSelectedTournamentFile(file);
  }

  const resetTournamentFile = () => {
    var tdfFilerSelector = document.getElementById("tdfFilerSelector") as HTMLInputElement;
    if (!tdfFilerSelector) {
      return;
    }
    tdfFilerSelector.value = "";
  }

  const resetStandings = () => {
    var standingsTextArea = document.getElementById("standingsMessage") as HTMLInputElement;
    if (!standingsTextArea) {
      return;
    }
    standingsTextArea.value = "";
  }

  return (
    <Fragment>
      <div className='uploadElements'>
        { eventData?.state === eventState.Finished &&
              <div className="eventStateBanner"> 
                  <div className="eventStateBannerData">
                    <FaFlagCheckered className="eventStateBannerIcon"/>
                    <label className="glass-h1">Event Complete!</label>
                  </div>
                  <label className="glass-body">The final tournament file has been uploaded. Please do not push any new data unless it's a valid update.</label>
              </div>
        }
        <div className="cardWrapper">
          <GlassCard>
            <div className="cardInsertWithFooter">
              <div className="file-control-header">
                <label className="glass-title">Tournament File</label>
                <IconButton isEnabled={true} hasShadow={false} onPress={() => exportTdf(null)} iconType={IconButtonType.Download} hasBackground={false} label="Export last major TDF upload"/>
              </div>
              <label className="glass-body">{tournamentFile?.fileName} {tournamentFile?.gameType}</label>
              <br/>
              <div className="fileControls">
                {isCompatibilityMode ?
                    <div>
                      <AutoUploadControls
                        updateSelectedTournamentFile={automaticallySelectedTournamentFile}
                        isFinalUpload={isFinalUpload}
                        isCompatibilityMode={isCompatibilityMode}
                        toggleIsFinalUpload={() => setIsFinalUpload(!isFinalUpload)}
                        toggleIsCompatibilityMode={() => setIsCompatibilityMode(!isCompatibilityMode)}
                        uploadTournamentFile={uploadTournamentFile} />
                      <div className="glass-divider"/>
                      <br/>
                      <label className="glass-h2">Manual Upload</label>
                      <form className="radioForm">
                      <input
                        className="glass-fileSelect"
                        id="tdfFilerSelector"
                        type="file"
                        name="file"
                        accept=".tdf"
                        onChange={(e) => manuallySelectedTournamentFile(e)}
                        style={{ display: "block", margin: "10px auto" }}
                      />
                      </form>
                      {isTournamentFileSelectedManually ?
                        <div className="actionContainer">
                          <button className="uploader-button" onClick={(e) => uploadTournamentFile(shouldStoreTdf, isFinalUpload)} disabled={tournamentFile?.isUploading}>Upload File <FaRegPaperPlane className="glass-button-icon-right"/></button>
                        </div>
                        : null
                      }
                    </div>
                    :
                    <FusionControls
                      updateSelectedTournamentFile={automaticallySelectedTournamentFile}
                      isFinalUpload={isFinalUpload}
                      isCompatibilityMode={isCompatibilityMode}
                      toggleIsFinalUpload={() => setIsFinalUpload(!isFinalUpload)}
                      toggleIsCompatibilityMode={() => setIsCompatibilityMode(!isCompatibilityMode)}
                      uploadTournamentFile={uploadTournamentFile}
                      uploadFusionImport={uploadFusionImport}
                      editFusionDataChannel={editFusionDataChannel}
                      localFusionDataChannelSubject={localFusionDataChannelSubject}
                      exportTdf={exportTdf}
                      toggleShowDebugLogs={setShowDebugLogs}
                      uploadResult={tournamentFile?.uploadResult ?? null} />
                  }
                </div>
              </div>
            </GlassCard>
          </div>

          <div className="fusion-upload-footer">
              { tournamentFile &&
                <UploadTracker
                  isUploading={tournamentFile?.isUploading}
                  uploadResult={tournamentFile?.uploadResult}
                  defaultIcon={UploadTrackerIcon.Document}
                  defaultTitle={"Select a tournament file"}
                  defaultSubtitle={eventData?.pairingsReadableLastUpdated ? "Last upload: " + eventData?.pairingsReadableLastUpdated : "Upload info will appear here"}
                  uploadMessage="Uploading TDF..."
                  logbookBundles={fusionLogbooks}
                  showDebugLogs={showDebugLogs}/>
              }
          </div>
        <div className="cardWrapper">
          <GlassCard>
            <div className="cardInsertWithFooter">
              <label className="glass-title">Standings Report</label>
              <div className='standingsForm'>
                <textarea className="glass-textarea" id="standingsMessage" placeholder={standingsPlaceholder} onChange={(e) => updateSelectedStandingsValue(e.target.value)}></textarea>
                <div className="standingsControls">
                  <div className="standingsControls-columnLeft">
                  <DivisionSelector value={standings?.division ?? competitorDivision.Senior} onChange={standingsReportDivisionChange} />
                    <div className='standingsControls-inputBox'>
                      <input
                        className="glass-number-input"
                        type="number"
                        name="round"
                        min="1"
                        max="20"
                        value={standings?.round ?? ""}
                        onChange={(e) => manuallySetSelectedStandingsValue(e.target.valueAsNumber)}
                      />
                      <label className="glass-body">Round number</label>
                    </div>
                    <div className='standingsControls-inputBox'>
                      <input
                        className="glass-number-input"
                        type="number"
                        name="cutSize"
                        min="0"
                        max="50"
                        value={standings?.cutSize ?? ""}
                        onChange={(e) => setStandingsCutSize(e.target.valueAsNumber)}
                      />
                      <label className="glass-body">Cut Size</label>
                    </div>
                  </div>

                  <div className="standingsControls-columnRight">
                    <div className='standingsControls-inputBox'>
                      <label className="glass-switch">
                      <input
                        type="checkbox"
                        checked={standings?.isPrizeout ?? false}
                        onChange={(e) => setStandingsIsFinal(!standings?.isPrizeout)}/>
                      <i></i>
                      </label>
                      <div className='data-pair'>
                        <label className="glass-body">Prize out</label>
                        <label className="glass-caption">Merges partial reports with existing data</label>
                      </div>
                    </div>
                    <div className='standingsControls-inputBox'>
                      <label className="glass-switch">
                      <input
                        type="checkbox"
                        checked={standings?.isDeleteInstruction ?? false}
                        onChange={(e) => setStandingsIsDeleteInstruction(!standings?.isDeleteInstruction)}/>
                      <i></i>
                      </label>
                      <div className='data-pair'>
                        <label className="glass-body">Reset server data</label>
                        <label className="glass-caption">Deletes previous uploads</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="actionContainer">
                  <button className="uploader-button" onClick={(e) => {uploadStandings()}} hidden={standings?.isUploading}>Upload Standings <FaTrophy className="glass-button-icon-right"/></button>
                  {standings?.isUploading && (<Loader showMessage={false} spinnerType={SpinnerType.FullPage}/>)}
                </div>
              </div>
              <label className="glass-body">{standings?.uploadResult?.summaryTitle}</label>
              <label className="glass-body">{standings?.calculationMessage}</label>
            </div>
          </GlassCard>
        </div>
        <div className="card-footer">
          { standings &&
            <UploadTracker
              isUploading={standings?.isUploading}
              uploadResult={standings?.uploadResult}
              defaultIcon={UploadTrackerIcon.Report}
              defaultTitle={"Paste a standings report"}
              defaultSubtitle={eventData?.standingsReadableLastUpdated ? "Last upload: " + eventData?.standingsReadableLastUpdated : "Upload info will appear here"}
              uploadMessage="Uploading TDF..."/>
          }
        </div>

        <motion.div
          initial="offscreen"
          animate={animationControl}
          variants={animationVariants}
          className="apiSampleElements">
          <div className="resultContainerTabs">
            <button className={"tabButton " + (apiSample?.selectedTab === apiSampleTabOptions.endOfRound ? 'tabSelected' : '')} onClick={(e) => switchResultsTab(apiSampleTabOptions.endOfRound, apiSampleSelectedDivision)}>Remaining Tables</button>
            <button className={"tabButton " + (apiSample?.selectedTab === apiSampleTabOptions.pairings ? 'tabSelected' : '')} onClick={(e) => switchResultsTab(apiSampleTabOptions.pairings, apiSampleSelectedDivision)}>Pairings</button>
            <button className={"tabButton " + (apiSample?.selectedTab === apiSampleTabOptions.liveResults ? 'tabSelected' : '')} onClick={(e) => switchResultsTab(apiSampleTabOptions.liveResults, apiSampleSelectedDivision)}>Live Results</button>
            <button className={"tabButton " + (apiSample?.selectedTab === apiSampleTabOptions.standings ? 'tabSelected' : '')} onClick={(e) => switchResultsTab(apiSampleTabOptions.standings, apiSampleSelectedDivision)}>Standings</button>
            <button className={"tabButton " + (apiSample?.selectedTab === apiSampleTabOptions.prizeout ? 'tabSelected' : '')} onClick={(e) => switchResultsTab(apiSampleTabOptions.prizeout, apiSampleSelectedDivision)}>Prize Out</button>
          </div>
          <div className="dark-glass-card apiSampleContainer">
            <textarea className="resultMessage" id="resultText" value={apiSample?.response} readOnly></textarea>
            { apiSample && !apiSample?.isLoading ?
              <div className="actionButtonContainer">
                <DivisionSelector value={apiSampleSelectedDivision} isDark={true} onChange={(value) => {setApiSampleSelectedDivision(value); switchResultsTab(apiSample!.selectedTab, value)}} />
                <div className="actionButtonWrapper" onClick={(e) => copyText()}>
                  <FaCopy className="actionButton"/>
                </div>
                  <div className="actionButtonWrapper" onClick={(e) => switchResultsTab(apiSample!.selectedTab, apiSampleSelectedDivision)}>
                    <MdOutlineRefresh className="actionButton"/>
                  </div>
              </div>
              :
              <div className="loaderOverlay">
                <Loader showMessage={false} spinnerType={SpinnerType.Circular}/>
              </div>
            }
          </div>

          <div className="dark-glass-card apiEndpointsContainer">
            <div className="apiEndpoint">
                <label className="glass-h2">API endpoints:</label>
              </div>
              <div className="apiEndpoint">
                  <FaMapPin className="smallIcon"/>
                <a className="glass-body" href={acs.apiEventUrl+eventId+"/pairings"} target="_blank">/ api / events / {eventId} / pairings</a>
              </div>

              <div className="apiEndpoint">
                  <FaTape className="smallIcon"/>
                <a className="glass-body" href={acs.apiEventUrl+eventId+"/stream-feed"} target="_blank">/ api / events / {eventId} / stream-feed</a>
              </div>

              <div className="apiEndpoint">
                  <PiListNumbersFill className="smallIcon"/>
                <a className="glass-body" href={acs.apiEventUrl+eventId+"/pairings/round/1"} target="_blank">/ api / events / {eventId} / pairings / round / (number)</a>
              </div>

              <div className="apiEndpoint">
                  <FaStar className="smallIcon"/>
                <a className="glass-body" href={acs.apiEventUrl+eventId+"/standings"} target="_blank">/ api / events / {eventId} / standings</a>
              </div>
            </div>

            <div className="dark-glass-card apiEndpointsContainer">
            <div className="apiEndpoint">
                <label className="glass-h2">Dashboards:</label>
              </div>
              <div className="apiEndpoint">
                  <LuListChecks className="smallIcon"/>
                <a className="glass-body" href={"/events"} target="_blank">List all events</a>
              </div>

              <div className="apiEndpoint">
                  <MdOutlineCloudUpload className="smallIcon"/>
                <a className="glass-body" href={"/web/events/"+eventId+"/"} target="_blank">Upload to {eventId}</a>
              </div>

              <div className="apiEndpoint">
                  <IoPeopleCircleOutline className="smallIcon"/>
                <a className="glass-body" href={"/web/events/"+eventId+"/pairings"} target="_blank">Pairings for {eventId}</a>
              </div>

              <div className="apiEndpoint">
                  <TbBuildingBroadcastTower className="smallIcon"/>
                <a className="glass-body" href={"/web/events/"+eventId+"/stream"} target="_blank">Stream data controls for {eventId}</a>
              </div>
            </div>
        </motion.div>
      </div>
    </Fragment>
  );
}
export default Uploader;