export enum UpdateNotificationType {
    EventData,
    EventGroup,
    Pairings,
    Standings,
    PrizeoutStandings,
    PrizeoutRecord,
}

export class DataUpdateNotificationMessage {
    id: string;
    type: number;
    recordIdentifier: string;
    timestamp: string;
}