import { useEffect, Fragment, useState } from "react";
import { motion, view } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './PrizeoutDashboard.scss';
import React from 'react';
import { PrizeoutDashboardViewModel } from "./PrizeoutDashboard.ViewModel";
import { Subscription } from "rxjs";
import PrizeoutStandingsList from "../PrizeoutStandingsList/PrizeoutStandingsList";
import PrizeoutEventSelector from "../PrizeoutEventSelector/PrizeoutEventSelector";
import { PrizeoutEventSelectorViewModel } from "../PrizeoutEventSelector/PrizeoutEventSelector.ViewModel";
import { NavbarProperties } from "../../components/navbar/Navbar.ViewModel";
import { QueryParamService } from "../../Services/QueryParamService";
import Loader, { SpinnerType } from "../../components/Spinner/Loader";

const animationVariants = {
    offscreen: { delay: 0, opacity: 0, scale: 0.6, transition: { duration: 0.5 } },
    onscreen: { delay: 0, opacity: 1, scale: 1, transition: { duration: 0.2 } }
};

interface PrizeoutDashboardProps {
    navbarProperties: NavbarProperties;
    isReadOnly: boolean;
}

const PrizeoutDashboard: React.FC<PrizeoutDashboardProps> = ({navbarProperties, isReadOnly}) => {
    const pdViewModel = new PrizeoutDashboardViewModel(navbarProperties, isReadOnly);
    return (
        <PrizeoutDashboardConstructed viewModel={pdViewModel}/>
    );
}

interface PrizeoutDashboardConstructed {
    viewModel: PrizeoutDashboardViewModel;
}

const PrizeoutDashboardConstructed: React.FC<PrizeoutDashboardConstructed> = ({ viewModel })  => {
    const [isSelectingEvents, setIsSelectingEvents] = useState(false);
    const [selectedEvents, setSelectedEvents] = useState(viewModel.selectedEvents.value);
    const [isLoadingEvents, setIsLoadingEvents] = useState(true);

    useEffect(() => {
        var subs: Subscription[] = [];
        subs.push(viewModel.selectedEvents.subscribe((events) => setSelectedEvents([...events])));
        subs.push(viewModel.isLoadingEvents.subscribe((value) => setIsLoadingEvents(value)));
        return () => subs.forEach(s => s.unsubscribe());
    }, []);

    useEffect(() => {
        setIsSelectingEvents(selectedEvents.length === 0);
    }, [selectedEvents]);

    QueryParamService.alertOnRefresh();

    return (
        <Fragment>
            <motion.div
                initial="onscreen"
                variants={animationVariants}
                className="prizeoutDashboard-content">

                {isSelectingEvents &&
                <div className="fullscreen-overlay">
                    <PrizeoutEventSelector
                    viewModel={
                        new PrizeoutEventSelectorViewModel(
                            viewModel.selectedEvents.value,
                            viewModel.selectedEventGroups.value,
                            viewModel.updateSelected,
                            () => setIsSelectingEvents(false))
                    }/>
                </div>
                }

                {isLoadingEvents ? (
                    <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />
                ) : (
                <PrizeoutStandingsList
                    combinedStandingsSubject={viewModel.combinedStandings}
                    selectedEventsSubject={viewModel.selectedEvents}
                    getStatistics={viewModel.getStatistics}
                    onChangeSelectedEvents={() => setIsSelectingEvents(true)}
                    onUsernameChange={viewModel.updateUsername}
                    currentUsername={viewModel.currentUsername}
                    editPrizeoutComment={(prizeoutUid: string, comment: string | null) => viewModel.editPrizeoutComment(prizeoutUid, comment)}
                    editPrizeAwarded={(prizeoutUid: string, prizeGuid: string, prizePointsAwarded: number | null) => viewModel.editPrizeAwarded(prizeoutUid, prizeGuid, prizePointsAwarded)}
                />
                )}
            </motion.div>
        </Fragment>
    );
}

export default PrizeoutDashboard;