import { BehaviorSubject } from "rxjs";
import { eventDetails } from "../../Models/Common/EventDetails";
import { eventState } from "../../Models/Common/EventState";
import { eventData, EventDivisionsData } from "../../Models/Outbound/EventData";
import { ApiConnectService } from "../../Services/ApiConnectService";
import tournamentService from "../../Services/TournamentDataService";

export class PrizeoutEventSelectorViewModel {
    selectableEvents = new BehaviorSubject<PrizeoutEventData[]>([]);
    selectedEvents = new BehaviorSubject<PrizeoutEventData[]>([]);
    selectableGroups = new BehaviorSubject<string[]>([]);
    selectedGroups = new BehaviorSubject<string[]>([]);
    isLoading = new BehaviorSubject<Boolean>(true);
    private finishedSelecting: (selectedEvents: PrizeoutEventData[], selectedGroups: string[]) => void;
    private closeWithoutSaving: () => void;

    constructor(
        selectedEvents: PrizeoutEventData[],
        selectedGroups: string[],
        finishedSelecting: (selectedEvents: PrizeoutEventData[], selectedGroups: string[]) => void,
        closeWithoutSaving: () => void) {
        this.finishedSelecting = finishedSelecting;
        this.closeWithoutSaving = closeWithoutSaving;
        this.getAllEvents();
        
        // Check for selectedEvents in query params
        this.isLoading.subscribe(isLoading => {
            if (!isLoading) {
                const params = new URLSearchParams(window.location.search);
                const eventIds = params.get('selectedEvents');
                if (eventIds) {
                    const eventIdsArray = eventIds.split(',');
                    this.selectEventsByIds(eventIdsArray);
                } else {
                    this.selectedEvents.next(selectedEvents);
                }
                const groupIds = params.get('selectedGroups');
                if (groupIds) {
                    const groupIdsArray = groupIds.split(',');
                    this.selectGroupsByIds(groupIdsArray);
                } else {
                    this.selectGroupsByIds(selectedGroups);
                }
            }
        });
    }

    getAllEvents = () => {
        console.log("Converting events");
        tournamentService.GetAllEvents().subscribe(apiEvents => {
            if (apiEvents.result) {
                var convertedEvents = apiEvents.result.events
                    .filter(event => event.config.isPrizeoutEnabled)
                    .filter(event => ![eventState.Cancelled, eventState.Deleted].includes(event.state))
                    .map(event => new PrizeoutEventData(event));
                this.selectableEvents.next(convertedEvents);
                const uniqueGroupIds = Array.from(new Set(convertedEvents.map(event => event.groupId).filter(groupId => groupId !== null && groupId !== ''))) as string[];
                this.selectableGroups.next(uniqueGroupIds);
            }
            this.isLoading.next(false);
        });
    }

    selectEventsByIds = (eventIds: string[]) => {
        this.selectableEvents.subscribe(events => {
            const selectedEvents = events.filter(event => eventIds.includes(event.eventId));
            this.selectedEvents.next(selectedEvents);
        });
    }

    selectGroupsByIds = (groupIds: string[]) => {
        this.selectableGroups.subscribe(groups => {
            const selectedGroups = groups.filter(groupId => groupIds.includes(groupId));
            this.selectedGroups.next(selectedGroups);
        });
    }

    toggleEventSelection = (event: PrizeoutEventData) => {
        console.log("Event toggled: " + event.eventId);
        console.log("Selected events: " + this.selectedEvents.value.length);
        let updatedSelectedEvents: PrizeoutEventData[];
        if (this.selectedEvents.value.some(selectedEvent => selectedEvent.eventId === event.eventId)) {
            updatedSelectedEvents = this.selectedEvents.value.filter(selectedEvent => selectedEvent.eventId !== event.eventId);
        } else {
            updatedSelectedEvents = [...this.selectedEvents.value, event];
        }
        this.selectedEvents.next(updatedSelectedEvents);
        console.log("Selected events: " + this.selectedEvents.value.length);
    }

    toggleGroupSelection = (groupId: string) => {
        console.log("Group toggled: " + groupId);
        console.log("Selected groups: " + this.selectedGroups.value.length);
        let updatedSelectedGroups: string[];
        if (this.selectedGroups.value.includes(groupId)) {
            updatedSelectedGroups = this.selectedGroups.value.filter(selectedGroup => selectedGroup !== groupId);
        } else {
            updatedSelectedGroups = [...this.selectedGroups.value, groupId];
        }
        this.selectedGroups.next(updatedSelectedGroups);
        console.log("Selected groups: " + this.selectedGroups.value.length);
    }

    deselectAll = () => {
        this.selectedEvents.next([]);
        this.selectedGroups.next([]);
    }

    didFinishSelecting = () => {
        this.finishedSelecting(this.selectedEvents.value, this.selectedGroups.value);
    }

    resetAndClose = () => {
        this.closeWithoutSaving();
    }
}

export class PrizeoutEventData {
    eventId: string;
    groupId: string | null;
    state: eventState;
    details: eventDetails;
    availableStandingsDivisions: EventDivisionsData[];
    standingsLastUpdated: string;

    constructor(apiEventData: eventData) {
        this.eventId = apiEventData.eventId;
        this.groupId = apiEventData.eventGroupId;
        this.state = apiEventData.state;
        this.details = apiEventData.details;
        this.availableStandingsDivisions = apiEventData.availableStandingsDivisions;
        this.standingsLastUpdated = apiEventData.standingsLastUpdated;
    }
}